<template>
  <v-list-group v-if="currentCase" v-model="expanded">
    <template #activator>
      <v-list-item-title> Messages </v-list-item-title>
      <v-list-item-action>
        <MenuMore offset-x offset-y>
          <v-list dense>
            <v-list-item @click="downloadMessageLog">
              <v-list-item-icon>
                <v-icon>get_app</v-icon>
              </v-list-item-icon>
              <v-list-item-content> Download Messages </v-list-item-content>
            </v-list-item>
            <v-list-item @click="clearMessages">
              <v-list-item-icon>
                <v-icon>clear_all</v-icon>
              </v-list-item-icon>
              <v-list-item-content> Clear Messages </v-list-item-content>
            </v-list-item>
          </v-list>
        </MenuMore>
      </v-list-item-action>
    </template>
    <template #default>
      <v-scroll-x-transition group>
        <v-list-item v-for="message in messages" :key="message.id" dense>
          <v-list-item-content class="pt-0 pb-1 pl-2">
            <v-list-item-title class="caption">
              {{ message.message }}
            </v-list-item-title>
            <v-list-item-subtitle class="caption">{{
              new Date(message.created).toLocaleString()
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-scroll-x-transition>
    </template>
  </v-list-group>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";

export default {
  name: "MessageList",
  components: {
    MenuMore: () => import("@/components/MenuMore"),
  },
  props: {
    currentCase: {
      type: Object,
      required: true,
    },
    jobInProgress: {
      type: Boolean,
      required: false,
      default: false,
    },
    loadInterval: {
      type: Number,
      required: false,
      default: 5,
    },
  },
  data() {
    return {
      expanded: true,
      loadMessageInterval: null,
    };
  },
  computed: {
    ...mapState({}),
    ...mapGetters({
      getMessages: "messages/find",
    }),
    query() {
      return {
        case_id: this.currentCase.id,
        $sort: { id: -1 },
        cleared: false,
      };
    },
    getMessagesForCase() {
      return this.getMessages({
        query: this.query,
      });
    },
    messages() {
      return this.getMessagesForCase.data;
    },
  },
  watch: {},
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {
    this.loadMessagesForCase();
    this.loadMessageInterval = setInterval(() => {
      if (this.jobInProgress) {
        this.loadMessagesForCase();
      }
    }, this.loadInterval * 1000);
  },
  beforeUpdate() {},
  updated() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {
    clearInterval(this.loadMessageInterval);
  },
  destroyed() {},
  methods: {
    ...mapMutations({
      clearMessageStore: "messages/clearAll",
    }),
    ...mapActions({
      loadMessages: "messages/find",
      patchMessages: "messages/patch",
      downloadMessages: "messages/download",
    }),
    downloadMessageLog() {
      console.debug("downloading messages for case", this.currentCase.id);
      return this.downloadMessages(this.currentCase.id).then(() => {
        console.debug("messages downloaded");
      });
    },
    loadMessagesForCase() {
      this.loadMessages({
        query: this.query,
      }).then((res) => {
        console.debug("Messages:", res);
      });
    },
    clearMessages() {
      this.clearMessageStore();
      this.patchMessages([null, { cleared: true }, { query: { case_id: this.currentCase.id } }]);
    },
  },
};
</script>

<style></style>
